import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ApiServices } from "../shared/api.services";
import { NgForm } from "@angular/forms";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-questionnaire",
  templateUrl: "./questionnaire.component.html",
  styleUrls: ["./questionnaire.component.css"],
})
export class QuestionnaireComponent implements OnInit {
  title = this.translate.data["Questionnaire"];
  isAlert = false;
  alertClass = "";
  alertMsg = "";
  modelError = "";

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  question_id: string;

  // Form Fields
  question: string = "";
  sequence: number;
  status = "true";
  frequency = "daily";

  public options: any[] = [
    {
      label: "",
      color: "",
    },
    {
      label: "",
      color: "",
    },
  ];

  questions_data: any;
  question_info: any;
  tot_ques: number;

  color_codes = [
    {
      name: "Dark Gray",
      code: "#A3A3A3",
    },
    {
      name: "Tangerine Yellow",
      code: "#FFCC02",
    },
    {
      name: "Neon Pink",
      code: "#F866D5",
    },
    {
      name: "Yellow Green",
      code: "#83c340",
    },
    {
      name: "Light Sea Green",
      code: "#29c2c2",
    },
    {
      name: "Summer Sky",
      code: "#2d9cdb",
    },
    {
      name: "Casper",
      code: "#aab7b7",
    },
    {
      name: "Moon Yellow",
      code: "#f1c40f",
    },
    {
      name: "Tangerine",
      code: "#e18a00",
    },
    {
      name: "Cinnabar",
      code: "#e74c3c",
    },
  ];

  // color_codes = ['#A3A3A3', '#FFCC02', '#F866D5', '#83c340', '#29c2c2', '#2d9cdb', '#aab7b7', '#f1c40f', '#e18a00', '#e74c3c', '#d43480', '#ad159e', '#37b4e1', '#34495e', '#dbadff']

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoaderShow = false;
    this.getQuestions({});
  }

  getQuestions(params: {}) {
    this.apiServices.getQuestions(params).subscribe((res) => {
      this.isLoaderShow = true;
      this.questions_data = res["data"];
    });
  }

  fillForm() {
    this.modelError = "";
    if (this.isEditable) {
      // For Edit Question
      let questions_data = this.question_info;
      this.question = questions_data["question"];
      this.sequence = questions_data["sequence"];
      this.status = `${questions_data["status"]}`;
      this.frequency = questions_data["frequency"];
      this.options = questions_data["options"];
    } else {
      this.question = "";
      this.sequence = 1;
      this.status = "";
      this.frequency = "daily";
      this.options = [
        {
          label: "",
          color: "",
        },
        {
          label: "",
          color: "",
        },
      ];
    }
  }

  getQuestionById(_id: string) {
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.question_id = _id;
      let params = {};
      params["_id"] = this.question_id;
      this.apiServices.getQuestions(params).subscribe((res) => {
        this.question_info = res["data"][0];
        this.fillForm();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.question_info = {};
      this.fillForm();
      this.isModelShow = true;
    }
  }

  onSubmit(form: NgForm) {
    this.cleanForm(form);
    if (form.valid == true) {
      this.modelError = "";
      // Form options as array
      let options_arr = [];
      for (var i = 0; i < Object.keys(form.value).length; i++) {
        if (form.value.hasOwnProperty("label_" + i)) {
          let obj = {
            label: form.value[`label_${i}`],
            color: form.value[`color_${i}`],
          };
          options_arr.push(obj);
        }
      }
      if (this.isEditable) {
        let params = {
          question: form.value.question,
          options: options_arr,
          sequence: form.value.sequence,
          status: form.value.status,
          frequency: form.value.frequency,
        };
        this.apiServices.updateQuestion(this.question_id, params).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.getQuestions({});
              // this.successAlert(res['msg']);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              // this.errorAlert(res['msg']);
              /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
              this.modelError = res["msg"];
              this.scrollTop();
            }
          },
          (error) => {
            this.errorAlert(error);
          }
        );
      } else {
        let params = {
          question: form.value.question,
          options: options_arr,
          sequence: form.value.sequence,
          frequency: form.value.frequency,
        };
        this.apiServices.addQuestion(params).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.getQuestions({});
              // this.successAlert(res['msg']);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              // this.errorAlert(res['msg']);
              /*  this.apiServices.sendTextMessage(res['msg'], 'danger');
             this.colseModel(); */
              this.modelError = res["msg"];
              this.scrollTop();
            }
          },
          (error) => {
            this.errorAlert(error);
          }
        );
      }
    } else {
      this.modelError =
        this.translate.data["Please select all mandatory(*) values!"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
    this.colseModel();
  }

  addOption() {
    this.options.push({
      label: "",
      color: "",
    });
  }

  removeOption(i: number): void {
    this.options.splice(i, 1);
  }

  getColor(code) {
    return code;
    switch (code) {
      case "UK":
        return "green";
      case "USA":
        return "blue";
      case "HK":
        return "red";
    }
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }

  cleanForm(form: NgForm) {
    Object.keys(form.value).forEach((key) => {
      if (typeof form.value[key] == "string") {
        form.value[key] = form.value[key].trim();
      }
    });
  }
}
