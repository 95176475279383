export class AppConstants {
  public static get prodURL(): string {
    return "http://yamazaki-juku.com:8500/";
  } //PROD server
  public static get testURL(): string {
    return "http://128.199.129.139:8500/";
  } //Dev Server
  public static get newProdURL(): string {
    return "http://202.182.127.236:8500/";
  } //new PROD
  public static get devURL(): string {
    return "https://a61e-206-189-9-60.eu.ngrok.io/";
  } // Local env
  public static get tinyApiKey(): string {
    // return 'lid3wvrw99ws4gekzw5kt2kklgb6t32zlu8dkeu09vb0umif';
    return "d8pgjfogshzz2tplweapmzle7kueuhrqq5k6jdlxv66oeumk";
  } // TinyMce Api Key
  public static get apiVersion(): string {
    return "api_v106/";
  } // API Version
  public static get tinyInit(): {} {
    return {
      selector: "textarea",
      plugins: "lists code emoticons wordcount paste",
      menubar: "file edit view insert format tools table help",
      toolbar:
        "undo redo | styleselect| bold italic forecolor backcolor permanentpen formatpainter | removeformat | emoticons",
      spellchecker_dialog: true,
      spellchecker_whitelist: ["Ephox", "Moxiecode"],
      tinycomments_mode: "embedded",
      height: 220,
      paste_data_images: false,
      paste_preprocess: function (plugin, args) {
        args.content = "";
      },
    };
  }
}
