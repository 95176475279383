import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  NgForm,
} from "@angular/forms";
declare var google;

import { ApiServices } from "../shared/api.services";
import { PagerService } from "../services/pager.service";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.css"],
})
export class LocationsComponent implements OnInit {
  title = "Locations";
  base_url;
  modelError = "";

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  location_id: string;

  locationForm: FormGroup;
  latitude = 17.385;
  longitude = 78.4867;
  description = "";

  locations;
  location_list: any;
  tot_locations: number;
  location_data: {} = {};

  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  skip: number = 0;

  filters: any = {};

  isAlert = false;

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  onSearch(form: NgForm) {
    this.search_key = form.value.search_user;
    this.setPage(1, this.pageSize);
  }

  getLocations(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getLocations(filters).subscribe((locations) => {
        resolve(locations);
      });
    });
  }

  getLocationById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.location_id = _id;
      this.filters["_id"] = this.location_id;
      this.getLocations(this.filters).then((location_info) => {
        this.location_data = location_info["data"][0];
        this.initFrom();
        this.isModelShow = true;
        return;
      });
    } else {
      this.isEditable = false;
      this.location_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.iconSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  selectedImage: File = null;
  imageSrc: any;
  onImageSelected(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  private initFrom() {
    this.modelError = "";
    let name = "";
    let areaName = "";
    let description = this.description;
    let latitude = this.latitude;
    let longitude = this.longitude;
    this.iconSrc = "";
    this.imageSrc = "";
    let activeStatus = "true";

    if (this.isEditable) {
      let location = this.location_data;
      name = location["name"];
      areaName = location["area_name"];
      description = location["description"];
      latitude =
        location["geo_location"] && location["geo_location"][1]
          ? location["geo_location"][1]
          : 0;
      longitude =
        location["geo_location"] && location["geo_location"][0]
          ? location["geo_location"][0]
          : 0;
      if (latitude != 0 && longitude != 0) {
        this.latitude = latitude;
        this.longitude = longitude;
      }
      activeStatus = `${location["status"]}`;
      this.iconSrc = this.base_url + location["icon"];
      this.imageSrc = this.base_url + location["image"];
    }
    let iconValidation = [];
    if (this.iconSrc == "") {
      iconValidation.push(Validators.required);
    }
    let imageValidation = [];
    if (this.imageSrc == "") {
      imageValidation.push(Validators.required);
    }
    this.locationForm = new FormGroup({
      name: new FormControl(name, Validators.required),
      area_name: new FormControl(areaName, Validators.required),
      description: new FormControl(description, Validators.required),
      latitude: new FormControl(latitude, Validators.required),
      longitude: new FormControl(longitude, Validators.required),
      // 'icon': new FormControl('', iconValidation),
      // 'image': new FormControl('', imageValidation),
      status: new FormControl(activeStatus, Validators.required),
    });
  }

  onChoseLocation(event) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    let geocoder = new google.maps.Geocoder();
    let latlng = { lat: this.latitude, lng: this.longitude };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status == "OK") {
        this.description = results[0].formatted_address;
      }
    });
  }

  onSubmit() {
    if (this.locationForm.valid == true) {
      this.modelError = "";
      const fd = new FormData();
      // if (this.selectedIcon != null)
      //   fd.append('icon', this.selectedIcon, this.selectedIcon.name);
      // if (this.selectedImage != null)
      //   fd.append('image', this.selectedImage, this.selectedImage.name);
      fd.append("name", this.locationForm.value.name);
      fd.append("area_name", this.locationForm.value.area_name);
      fd.append("description", this.locationForm.value.description);
      fd.append("geo_location" + [], this.locationForm.value.longitude);
      fd.append("geo_location" + [], this.locationForm.value.latitude);
      fd.append("status", this.locationForm.value.status);

      if (this.isEditable) {
        this.apiServices.editLocation(this.location_id, fd).subscribe((res) => {
          if (res["status"] === "success") {
            this.setPage(this.pager.currentPage, this.pageSize);
            this.getLocations(this.filters);
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.colseModel();
            this.scrollTop();
          } else {
            // this.modelError = res['msg'];
            this.apiServices.sendTextMessage(res["msg"], "danger");
            this.colseModel();
            this.scrollTop();
          }
        });
      } else {
        this.apiServices.addLocation(fd).subscribe((res) => {
          if (res["status"] === "success") {
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.colseModel();
            this.scrollTop();
          } else {
            // this.modelError = res['msg'];
            this.apiServices.sendTextMessage(res["msg"], "danger");
            this.colseModel();
            this.scrollTop();
          }
        });
      }
    } else {
      this.modelError =
        this.translate.data["Please select all mandatory(*) values!"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  colseModel() {
    this.locationForm.reset();
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }
    // set search key
    this.filters["search_key"] = this.search_key;
    this.getLocations(this.filters)
      .then((userdata) => {
        this.location_list = userdata;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        this.isAlert = true;
        // total count
        this.tot_locations = this.location_list["total"];
        // users list
        this.locations = this.location_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(
          this.tot_locations,
          page,
          pageCount
        );
        this.pagedItems = this.locations;
        this.pagedItemsLength = this.pagedItems.length;
      });
    delete this.filters.limit;
    delete this.filters.skip;
  }
}
