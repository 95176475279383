import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { ApiServices } from "../shared/api.services";
import { PagerService } from "../services/pager.service";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
  styleUrls: ["./languages.component.css"],
})
export class LanguagesComponent implements OnInit {
  title = "Languages";
  base_url;
  isAlert = false;
  alertClass = "";
  alertMsg = "";
  modelError = "";

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;
  language_id: string;

  languageForm: FormGroup;

  languages;
  language_data: {} = {};
  lang_list: any;
  tot_lang: number;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private translate: TranslateService
  ) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  getLanguages(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getLanguages(filters).subscribe((locations) => {
        this.isLoaderShow = true;
        resolve(locations);
      });
    });
  }

  getLanguageById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.language_id = _id;
      let params = {};
      params["_id"] = this.language_id;
      this.filters["_id"] = this.language_id;
      this.getLanguages(params).then((language_info) => {
        this.language_data = language_info["data"][0];
        this.initFrom();
        this.isModelShow = true;
        return;
      });
    } else {
      this.isEditable = false;
      this.language_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.iconSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  selectedImage: File = null;
  imageSrc: any;
  onImageSelected(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  private initFrom() {
    this.modelError = "";
    let name = "";
    let lang = "";
    let activeStatus = "";

    if (this.isEditable) {
      let language = this.language_data;
      name = language["name"];
      lang = language["lang"];
      activeStatus = `${language["status"]}`;
    }
    this.languageForm = new FormGroup({
      name: new FormControl(name, Validators.required),
      lang: new FormControl(lang, Validators.required),
      status: new FormControl(activeStatus),
    });
  }

  onSubmit() {
    if (this.languageForm.valid == true) {
      this.isFormSubmit = true;
      this.modelError = "";
      const fd = new FormData();
      fd.append("name", this.languageForm.get("name").value);
      fd.append("lang", this.languageForm.get("lang").value);
      if (this.isEditable) {
        fd.append("status", this.languageForm.get("status").value);
        this.apiServices.editLanguage(this.language_id, fd).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.isFormSubmit = false;
              this.setPage(this.pager.currentPage, this.pageSize);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
              // this.successAlert(res['msg']);
            } else {
              this.isFormSubmit = false;
              this.modelError = res["msg"];
              // this.errorAlert(res['msg']);
              /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
              this.scrollTop();
            }
          },
          (error) => {
            this.errorAlert(error);
          }
        );
      } else {
        this.apiServices.addLanguage(fd).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.isFormSubmit = false;
              this.languageForm.reset();
              this.setPage(this.pager.currentPage, this.pageSize);
              // this.successAlert(res['msg']);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              this.isFormSubmit = false;
              this.modelError = res["msg"];
              // this.errorAlert(res['msg']);
              /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
              this.scrollTop();
            }
          },
          (error) => {
            this.errorAlert(error);
          }
        );
      }
    } else {
      this.modelError =
        this.translate.data["Please select all mandatory(*) values!"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    this.getLanguages(this.filters)
      .then((langdata) => {
        this.lang_list = langdata;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.tot_lang = this.lang_list["total"];
        // users list
        this.languages = this.lang_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.tot_lang, page, pageCount);
        // get current page of items
        this.pagedItems = this.languages;
        this.pagedItemsLength = this.pagedItems.length;
      });
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }
}
