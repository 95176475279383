import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { ApiServices } from "../shared/api.services";
import { PagerService } from "../services/pager.service";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-delivery-prefectures",
  templateUrl: "./delivery-prefectures.component.html",
  styleUrls: ["./delivery-prefectures.component.css"],
})
export class DeliveryPrefecturesComponent implements OnInit {
  title = this.translate.data["Delivery Prefectures"];
  base_url;
  isAlert = false;
  alertClass = "";
  alertMsg = "";
  modelError = "";

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;
  prefecture_id: string;

  prefectureForm: FormGroup;
  prefecture_title_ja = "";
  prefecture_title_en = "";

  prefectures;
  prefectures_data: {} = {};
  prefecture_list: any;
  tot_prefectures: number;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private translate: TranslateService
  ) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  fetchPrefectures(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.fetchPrefectures(filters).subscribe((prefectures) => {
        this.isLoaderShow = true;
        resolve(prefectures);
      });
    });
  }

  fetchPrefectureById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.prefecture_id = _id;
      let params = {};
      params["_id"] = this.prefecture_id;
      this.filters["_id"] = this.prefecture_id;
      this.fetchPrefectures(params).then((prefecture_info) => {
        this.prefectures_data = prefecture_info["data"][0];
        this.initFrom();
        this.isModelShow = true;
        return;
      });
    } else {
      this.isEditable = false;
      this.prefectures_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.iconSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  selectedImage: File = null;
  imageSrc: any;
  onImageSelected(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  private initFrom() {
    this.modelError = "";
    this.prefecture_title_ja = "";
    this.prefecture_title_en = "";
    let delivery_charge = "";
    let activeStatus = "";

    if (this.isEditable) {
      let prefecture = this.prefectures_data;
      this.prefecture_title_ja = prefecture["name"]["ja"];
      this.prefecture_title_en = prefecture["name"]["en"];
      delivery_charge = prefecture["delivery_charge"];
      activeStatus = `${prefecture["status"]}`;
    }
    this.prefectureForm = new FormGroup({
      prefecture_title_ja: new FormControl(
        this.prefecture_title_ja,
        Validators.required
      ),
      prefecture_title_en: new FormControl(
        this.prefecture_title_en,
        Validators.required
      ),
      delivery_charge: new FormControl(delivery_charge, [
        Validators.required,
        Validators.min(0),
      ]),
      status: new FormControl(activeStatus),
    });
  }

  onSubmit() {
    this.apiServices.cleanForm(this.prefectureForm);
    if (this.prefectureForm.valid == true) {
      this.isFormSubmit = true;
      this.modelError = "";
      const fd = new FormData();
      let name = {
        ja: this.prefectureForm.value.prefecture_title_ja,
        en: this.prefectureForm.value.prefecture_title_en,
      };
      fd.append("name", JSON.stringify(name));
      fd.append("lang", localStorage.getItem("lang") || "en");
      fd.append("delivery_charge", this.prefectureForm.value.delivery_charge);

      if (this.isEditable) {
        fd.append("status", this.prefectureForm.value.status);
        this.apiServices.editPrefecture(this.prefecture_id, fd).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.isFormSubmit = false;
              this.setPage(this.pager.currentPage, this.pageSize);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
              // this.successAlert(res['msg']);
            } else {
              this.isFormSubmit = false;
              this.modelError = res["msg"];
              // this.errorAlert(res['msg']);
              /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
              this.scrollTop();
            }
          },
          (error) => {
            this.errorAlert(error);
          }
        );
      } else {
        this.apiServices.addPrefecture(fd).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.isFormSubmit = false;
              this.prefectureForm.reset();
              this.setPage(this.pager.currentPage, this.pageSize);
              // this.successAlert(res['msg']);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              this.isFormSubmit = false;
              this.modelError = res["msg"];
              // this.errorAlert(res['msg']);
              /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
              this.scrollTop();
            }
          },
          (error) => {
            this.errorAlert(error);
          }
        );
      }
    } else {
      this.modelError =
        this.translate.data["Please fill all the required fields"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    this.fetchPrefectures(this.filters)
      .then((prefectureData) => {
        this.prefecture_list = prefectureData;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.tot_prefectures = this.prefecture_list["total"];
        // users list
        this.prefectures = this.prefecture_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(
          this.tot_prefectures,
          page,
          pageCount
        );
        // get current page of items
        this.pagedItems = this.prefectures;
        this.pagedItemsLength = this.pagedItems.length;
      });
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }
}
