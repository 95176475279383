import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  NgForm,
} from "@angular/forms";

import { ApiServices } from "../shared/api.services";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-configurations",
  templateUrl: "./configurations.component.html",
  styleUrls: ["./configurations.component.css"],
})
export class ConfigurationsComponent implements OnInit {
  title = "App Configurations";
  base_url: string;

  configurationForm: FormGroup;
  submitted = false;
  modelError = "";

  // Form Fields
  appConfigurations: any;
  curr_version: string = "";
  isMaintenance = "false";
  maintenanceDate: string = "";
  in_app_purchase_version: string = "";
  in_app_purchase_status: string = "false";
  _id: string = "";
  isMaintenanceShow: boolean = false;

  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private translate: TranslateService
  ) {
    this.base_url = this.apiServices.base_url;
  }

  ngOnInit() {
    this.createConfigForm();
    this.fetchConfigurations();
  }

  createConfigForm() {
    this.modelError = "";
    let maintenanceValidations = [];
    if (this.isMaintenance == "true") {
      this.isMaintenanceShow = true;
      maintenanceValidations.push(Validators.required);
    } else {
      this.isMaintenanceShow = false;
      this.maintenanceDate = "";
    }

    this.configurationForm = new FormGroup({
      app_current_version: new FormControl(
        this.curr_version,
        Validators.required
      ),
      under_maintainance: new FormControl(
        this.isMaintenance,
        Validators.required
      ),
      till_date: new FormControl(this.maintenanceDate, maintenanceValidations),
      in_app_purchase_version: new FormControl(
        this.in_app_purchase_version,
        Validators.required
      ),
      in_app_purchase_status: new FormControl(this.in_app_purchase_status),
    });
  }

  fetchConfigurations() {
    this.apiServices.fetchConfigurations().subscribe((configurations) => {
      console.log(configurations);

      this.appConfigurations = configurations["data"];
      this._id = this.appConfigurations["_id"];
      this.curr_version = this.appConfigurations["app_current_version"];
      this.isMaintenance = `${this.appConfigurations["under_maintainance"]}`;
      this.maintenanceDate = this.appConfigurations["till_date"];
      this.in_app_purchase_version =
        this.appConfigurations["in_app_purchase_version"];
      this.in_app_purchase_status = `${this.appConfigurations["in_app_purchase_status"]}`;

      this.createConfigForm();
    });
  }

  changeStatus(e: any) {
    this.isMaintenance = e.target.value;
    this.createConfigForm();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.configurationForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.configurationForm.valid == true) {
      if (this.configurationForm.value.under_maintainance == "true") {
        this.configurationForm.value.under_maintainance = true;
      } else {
        this.configurationForm.value.under_maintainance = false;
      }
      this.apiServices
        .updateConfigurations(this._id, this.configurationForm.value)
        .subscribe((res) => {
          if (res["status"] == "success") {
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.fetchConfigurations();
            this.submitted = false;
          }
        });
    } else {
      this.submitted = false;
      this.modelError =
        this.translate.data["Please select all mandatory(*) values!"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }
}
