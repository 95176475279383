import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  NgForm,
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from "@angular/forms";

import { ApiServices } from "../shared/api.services";
import { PagerService } from "../services/pager.service";
import { VideoProcessingService } from "../services/video-processing-service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-announcement-videos",
  templateUrl: "./announcement-videos.component.html",
  styleUrls: ["./announcement-videos.component.css"],
})
export class AnnouncementVideosComponent implements OnInit {
  page_title = this.translate.data["Announcement videos"];
  base_url: string = "";
  isAlert = false;
  alertClass = "";
  alertMsg = "";
  modelError = "";

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;

  post_id: string = "";
  posts: any;
  posts_data: {} = {};
  posts_list: any;
  tot_posts: number;

  announcementForm: FormGroup;
  title: string = "";

  urls = [];
  isVideoUploaded = false;
  public thumbnailData: string;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  // Filters
  filters: any = {};

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private videoService: VideoProcessingService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initForm();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  fetchAnnounceVideos(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.fetchAnnounceVideos(filters).subscribe((posts) => {
        resolve(posts);
      });
    });
  }

  getAnnouncementById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.imgPreview = undefined;
    this.imgPreview1 = undefined;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.post_id = _id;
      this.filters["_id"] = this.post_id;
      this.fetchAnnounceVideos(this.filters).then((post) => {
        this.posts_data = post["data"][0];
        this.initForm();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.title = "";
      this.posts_data = {};
      this.initForm();
      this.isModelShow = true;
    }
  }

  private initForm() {
    this.apiServices.clearMessage();
    this.modelError = "";
    this.title = "";
    let thumbnail = "";
    let activeStatus = "";
    let postUrl = "";
    this.imageName = "";
    this.videoName = "";

    if (this.isEditable) {
      this.title = this.posts_data["title"];
      activeStatus = `${this.posts_data["status"]}`;
      // Set image
      this.urls.push(this.base_url + this.posts_data["thumbnail"]);
      this.imageName = this.posts_data["thumbnail"];
      // Set video
      this.urls.push(this.base_url + this.posts_data["postUrl"]);
      this.videoName = this.posts_data["postUrl"];
      this.isVideoUploaded = true;
    }

    /* if (this.urls.length == 0) {
      this.announcementForm.get('postUrl').setValidators(Validators.required);
      this.announcementForm.updateValueAndValidity();
    } */

    this.announcementForm = this.fb.group({
      title: [this.title, Validators.required],
      // postUrl: [postUrl],
      // thumbnail: [thumbnail],
      status: [activeStatus],
    });
  }

  // For Single Image Upload
  selectedFile: any;
  imgPreview: any;
  imageName: string;
  onImageSelected(event) {
    // For Single image
    this.selectedFile = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.imageName = file.name;
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = (event) => (this.imgPreview = reader.result);
      // reader.readAsDataURL(file);
    }
  }

  // For Single video
  selectedFile1: any;
  imgPreview1: any;
  videoName: string;
  img_ext_err: Boolean = false;
  // onVideoSelected(event: any): void {
  //   this.videoService.promptForVideo().then(videoFile => {
  //     return this.videoService.generateThumbnail(videoFile);
  //   }).then(thumbnailData => {
  //     this.imgPreview = thumbnailData;
  //   })
  // }

  public add(): void {
    this.videoService
      .promptForVideo()
      .then((videoFile) => {
        let img_ext = videoFile.type.split("/");
        if (img_ext[0] != "video") {
          this.img_ext_err = true;
          return;
        } else {
          this.img_ext_err = false;
          this.selectedFile1 = videoFile;
          this.imgPreview1 = videoFile;
          this.videoName = videoFile.name;
          this.isVideoUploaded = true;
          return this.videoService.generateThumbnail(videoFile);
        }
      })
      .then((thumbnailData) => {
        this.imgPreview = thumbnailData;
        var img = thumbnailData;
        // Convert Base64 image to binary
        var file = this.dataURItoBlob(img);
        const imageFile = new File([file], "sample.jpeg", {
          type: "image/jpeg",
        });
        this.imageName = imageFile.name;
        this.selectedFile = imageFile;
      });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  progress: number = 0;
  onSubmit() {
    this.apiServices.cleanForm(this.announcementForm);
    if (this.announcementForm.valid && this.isVideoUploaded) {
      this.isFormSubmit = true;
      const fd = new FormData();

      if (this.selectedFile && this.selectedFile.name)
        fd.append("thumbnail", this.selectedFile, this.selectedFile.name);

      if (this.selectedFile1 && this.selectedFile1.name)
        fd.append("postUrl", this.selectedFile1, this.selectedFile1.name);

      fd.append("title", this.announcementForm.value.title);
      fd.append("lang", localStorage.getItem("lang") || "en");
      if (this.isEditable) {
        fd.append("status", this.announcementForm.value.status);
        fd.append("_id", this.post_id);
        this.apiServices.updateAnnouncementVideo(fd).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.isFormSubmit = false;
              this.isVideoUploaded = false;
              this.announcementForm.reset();
              this.setPage(this.pager.currentPage, this.pageSize);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              this.isFormSubmit = false;
              this.modelError = res["msg"];
              /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
              this.scrollTop();
            }
          },
          (error) => {
            this.isFormSubmit = false;
            this.modelError = error;
            this.scrollTop();
          }
        );
      } else {
        this.apiServices.addAnnouncementVideo(fd).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log("Request has been made!");
                break;
              case HttpEventType.ResponseHeader:
                console.log("Response header has been received!");
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round((event.loaded / event.total) * 100);
                console.log(`Uploaded! ${this.progress}%`);
                break;
              case HttpEventType.Response:
                console.log("User successfully created!", event.body);
                var res = event.body;
                if (res["status"] === "success") {
                  this.isFormSubmit = false;
                  this.isVideoUploaded = false;
                  this.announcementForm.reset();
                  this.setPage(this.pager.currentPage, this.pageSize);
                  this.apiServices.sendTextMessage(res["msg"], "success");
                  this.colseModel();
                  this.scrollTop();
                } else {
                  this.isFormSubmit = false;
                  this.modelError = res["msg"];
                  /* this.apiServices.sendTextMessage(res['msg'], 'danger');
                this.colseModel(); */
                  this.scrollTop();
                }
                setTimeout(() => {
                  this.progress = 0;
                }, 1500);
            }
          },
          (error) => {
            this.isFormSubmit = false;
            this.modelError = error;
            this.scrollTop();
          }
        );
      }
    } else {
      this.modelError =
        this.translate.data["Please fill all the required fields"];

      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
    this.urls = [];
    this.selectedFile = undefined;
    this.selectedFile1 = undefined;
    this.img_ext_err = false;
    this.imageName = "";
    this.videoName = "";
    this.imgPreview = "";
    this.imgPreview1 = "";
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    // set filters

    this.fetchAnnounceVideos(this.filters)
      .then((langdata) => {
        this.posts_list = langdata;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.tot_posts = this.posts_list["total"];
        // users list
        this.posts = this.posts_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(
          this.tot_posts,
          page,
          pageCount
        );
        // get current page of items
        this.pagedItems = this.posts;
        this.pagedItemsLength = this.pagedItems.length;
      });
  }

  resetFilters() {
    this.setPage(1, this.pageSize);
  }

  resetForm() {
    this.colseModel();
    this.urls = [];
    this.announcementForm.markAsUntouched();
  }
}
