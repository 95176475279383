import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import { isObject } from "util";
/* import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'; */

import { ApiServices } from "../../shared/api.services";
import { PagerService } from "../../services/pager.service";
import { TranslateService } from "src/app/shared/translate/translate.service";

declare var tinyMCE: any;
@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit {
  title = this.translate.data["Bulletin"];
  base_url: string;
  modelError = "";

  location_id: string;
  locations: any;
  language_id: string;
  languages: any;
  news_categories: any;

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;
  news_id: string;
  news_status: string = "";

  newsForm: FormGroup;
  pageSelectForm: FormGroup;

  news;
  news_data: {} = {};
  news_list: any;
  tot_news: number;

  //Pagination Values
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;
  role: string;
  search_key: string = "";
  pageSize: number = 10;
  skip: number = 0;
  filters: any = {};

  upload_file_url: string = "";
  // selectedFile1: File;
  apiKey = "";

  isAlert = false;

  // For Prod values
  description = {};
  news_title = {};
  title_en = "";
  title_ja = "";
  description_en = "";
  description_ja = "";

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  /**
   * subscription
   * selection
   */
  subscriptionType: string = "Free";
  // Product Plans
  productsList = [];
  selectedProducts = [];
  dropDownProductSettings = {};
  isPaidProduct: Boolean = false;
  productFilter: string = "";
  subscriptionFilter: string = "";

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.base_url = apiServices.base_url;
    this.apiKey = apiServices.tinyApiKey;
  }

  ngOnInit() {
    this.fetchProducts();
    this.initFrom();
    // this.getLanguages();
    // this.getLocations();
    // this.getNewsCategories();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);

    this.dropDownProductSettings = {
      singleSelection: false,
      text: "Select Plans",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      primaryKey: "_id",
      labelKey: "title",
      searchBy: ["title"],
    };
  }

  /*  public onReady(editor) {
     editor.ui.getEditableElement().parentElement.insertBefore(
       editor.ui.view.toolbar.element,
       editor.ui.getEditableElement()
     );
   } */

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  getLocations() {
    let params = {};
    this.apiServices.getLocations(params).subscribe((locations) => {
      this.locations = locations["data"];
    });
  }

  getNews(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getNews(filters).subscribe((news) => {
        resolve(news);
      });
    });
  }

  getNewsById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.imgPreview = undefined;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.news_id = _id;
      this.filters["_id"] = this.news_id;
      this.getNews(this.filters).then((news) => {
        this.news_data = news["data"][0];
        this.getLocations();
        this.initFrom(_id);
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.news_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  onSearch(form: NgForm) {
    this.search_key = form.value.search_user;
    this.setPage(1, this.pageSize);
  }

  // For multiple images
  imageSrc = new Array<File>();
  urls = [];
  // For Single image
  selectedFile: any;
  imgPreview: any;
  imageName: string;
  img_ext_err: Boolean = false;
  onImageSelected(event) {
    // For Single image
    this.selectedFile = <File>event.target.files[0];
    let img_ext = event.target.files[0].type.split("/");
    if (img_ext[0] != "image") {
      this.img_ext_err = true;
    } else {
      this.img_ext_err = false;
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        this.imageName = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onload = (event) => (this.imgPreview = reader.result);
        // reader.readAsDataURL(file);
      }
    }
    // For multiple images
    /* let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.imageSrc.push(<File>file);
        if (file.name) {
          const reader = new FileReader();
          reader.onload = e => this.urls.push(reader.result);
          reader.readAsDataURL(file);
        }
      }
    } */
  }

  private initFrom(id: string = null) {
    this.apiServices.clearMessage();
    this.modelError = "";
    this.title_ja = "";
    let activeStatus = "true";
    let bulletin_url = "";
    this.description_ja = "";
    let images = "";
    this.imageName = "";
    this.urls = [];
    let subscription_type = "";

    if (this.isEditable) {
      let news = this.news_data;
      this.upload_file_url = "";
      this.title_ja = news["title"];
      bulletin_url = news["url"];
      this.description_ja = news["description"];
      activeStatus = `${news["status"]}`;
      if (news.hasOwnProperty("header_image")) {
        this.imageName = news["header_image"];
        this.urls.push(this.base_url + news["header_image"]);
      }
      this.subscriptionType = subscription_type = news["subscription_type"];
      if (this.subscriptionType == "Paid") {
        this.isPaidProduct = true;
        this.selectedProducts = news.hasOwnProperty("product_ids")
          ? news["product_ids"]
          : [];
      } else {
        this.isPaidProduct = false;
        this.selectedProducts = [];
      }
    }
    if (!id) {
      this.subscriptionType = "Free";
      this.isPaidProduct = false;
      this.selectedProducts = [];
    }
    let imagesValidation = [];
    if (this.urls.length == 0) {
      imagesValidation.push(Validators.required);
    }
    this.newsForm = new FormGroup({
      upload_url: new FormControl(this.upload_file_url),
      title_ja: new FormControl(this.title_ja, Validators.required),
      status: new FormControl(activeStatus),
      bulletin_url: new FormControl(bulletin_url),
      description_ja: new FormControl(this.description_ja),
      images: new FormControl(images),
      subscription_type: new FormControl(this.subscriptionType),
      product_ids: new FormControl(this.selectedProducts),
    });
  }

  getLanguages() {
    let params = {};
    this.apiServices.getLanguages(params).subscribe((languages) => {
      this.languages = languages["data"];
    });
  }

  getNewsCategories() {
    let params = {};
    this.apiServices.getNewsCategories(params).subscribe((news_categories) => {
      this.news_categories = news_categories["data"];
    });
  }

  onSubmit() {
    var wordcount = tinyMCE.activeEditor.plugins.wordcount;
    this.apiServices.cleanForm(this.newsForm, "images");
    if (this.newsForm.valid == true) {
      this.isFormSubmit = true;
      this.modelError = "";
      const fd = new FormData();
      fd.append("lang", localStorage.getItem("lang") || "en");
      if (this.selectedFile && this.selectedFile.name) {
        fd.append("header_image", this.selectedFile, this.selectedFile.name);
      }
      fd.append("subscription_type", this.subscriptionType);
      //  Set product Ids
      if (this.subscriptionType == "Paid") {
        let productIds = this.selectedProducts.map((product) => product._id);
        console.log("productIds :", productIds);
        if (productIds.length == 0) {
          this.isFormSubmit = false;
          this.modelError =
            this.translate.data["Please fill all the required fields"];
          return null;
        }
        this.newsForm.value.product_ids = productIds;
        fd.append("product_ids", this.newsForm.value.product_ids);
      }
      fd.append("title", this.newsForm.get("title_ja").value);
      if (
        this.newsForm.get("bulletin_url").value != null &&
        this.newsForm.get("bulletin_url").value != ""
      )
        fd.append("url", this.newsForm.get("bulletin_url").value);
      if (
        this.newsForm.get("description_ja").value != null &&
        this.newsForm.get("description_ja").value != ""
      )
        fd.append("description", this.newsForm.get("description_ja").value);
      // fd.append('show_reply', this.newsForm.value.show_reply);
      if (this.isEditable) {
        fd.append("status", this.newsForm.get("status").value);
        this.apiServices.editNews(this.news_id, fd).subscribe((res) => {
          if (res["status"] === "success") {
            this.isFormSubmit = false;
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.colseModel();
            this.scrollTop();
          } else {
            this.isFormSubmit = false;
            this.modelError = res["msg"];
            // this.apiServices.sendTextMessage(res['msg'], 'danger');
            // this.colseModel();
            this.scrollTop();
          }
        });
      } else {
        this.apiServices.addNews(fd).subscribe((res) => {
          if (res["status"] === "success") {
            this.isFormSubmit = false;
            this.newsForm.reset();
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.colseModel();
            this.scrollTop();
          } else {
            this.isFormSubmit = false;
            this.modelError = res["msg"];
            // this.apiServices.sendTextMessage(res['msg'], 'danger');
            // this.colseModel();
            this.scrollTop();
          }
        });
      }
    } else {
      this.modelError =
        this.translate.data["Please fill all the required fields"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  colseModel() {
    this.newsForm.reset();
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
    this.urls = [];
    this.selectedFile = undefined;
    this.imageName = "";
    this.imgPreview = undefined;
    this.img_ext_err = false;
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // Checking Object or Not
    if (!isObject(this.filters)) {
      this.filters = {};
    }

    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }
    // set search key
    this.filters["search_key"] = this.search_key;

    // Set language id
    /*  if (this.language_code != "" && this.language_code != null) {
       this.filters['language_code'] = this.language_code;
     } */

    // Set category id
    /* if (this.category_code != "" && this.category_code != null) {
      this.filters['category_code'] = this.category_code;
    } */
    this.filters["subscription_type"] = this.subscriptionFilter;
    this.filters["product_ids"] = this.productFilter;
    // Set news status
    if (this.news_status != "") this.filters["status"] = this.news_status;

    this.getNews(this.filters)
      .then((news) => {
        this.news_list = news;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        this.isAlert = true;
        // total count
        this.tot_news = this.news_list["total"];
        // users list
        this.news = this.news_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.tot_news, page, pageCount);
        // get current page of items
        this.pagedItems = this.news;
        this.pagedItemsLength = this.pagedItems.length;
      });
    delete this.filters.limit;
    delete this.filters.skip;

    // this.filters['product_ids'] = this.productFilter;
  }

  // Loading news based on Language
  language_code: string = "";
  onChangeLanguage(event: any) {
    const language_id = event.target.value;
    delete this.filters.language_code;
    this.language_code = "";
    if (language_id != "") this.language_code = language_id;
    this.setPage(1, this.pageSize);
  }

  // Loading news based on Category
  category_code: string = "";
  onChangeCategory(event: any) {
    const category_id = event.target.value;
    delete this.filters.category_code;
    this.category_code = "";
    if (category_id != "") this.category_code = category_id;
    this.setPage(1, this.pageSize);
  }

  resetFilters() {
    this.emptyFilters();
    this.setPage(1, this.pageSize);
  }

  // Emptying all status variables when reset filters
  emptyFilters() {
    this.search_key = "";
    this.news_status = "";
    /* this.language_code = "";
    this.category_code = ""; */
    this.pageSize = 10;
    this.subscriptionFilter = "";
    this.productFilter = "";
    delete this.filters;
  }

  // Loading news based on Active/Inactive
  onChangeNewsStatus(event: any) {
    this.news_status = event.target.value;
    if (this.news_status == "") delete this.filters.status;
    /* let news_status = event.target.value;
    news_status = (news_status === "true") ? true : false;
    delete this.filters.news_status;
    this.news_status = news_status; */
    this.setPage(1, this.pageSize);
  }

  /* onFileChanged(event) {
    this.selectedFile1 = <File>event.target.files[0];
    var fd = new FormData();
    fd.append('config_image', this.selectedFile1, this.selectedFile1.name);
    this.apiServices.uploadFile(fd).subscribe(res => {
      if (res['status'] == "failure") {
        this.apiServices.clearMessage();
      } else {
        this.apiServices.clearMessage();
        this.upload_file_url = res['data'];
        // this.apiServices.sendTextMessage(this.upload_file_url, 'success');
      }
    });
  } */

  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
    alert("text copied..!");
  }

  /**
   * Change subscription
   */
  changeSubscription(subscription: string) {
    this.isPaidProduct = subscription == "Paid" ? true : false;
    this.subscriptionType = subscription;
  }
  onItemSelect(item: any) {
    // console.log(item);
  }
  OnItemDeSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }
  onDeSelectAll(items: any) {
    // console.log(items);
    this.selectedProducts = [];
  }
  fetchProducts() {
    this.apiServices.fetchProducts({ status: "true" }).subscribe((res) => {
      if (res["status"] == "success") {
        this.productsList = res["data"];
      }
    });
  }
  changeSubscriptionFilter(event: any) {
    this.subscriptionFilter = event.target.value;
    this.productFilter = "";
    this.setPage(1, this.pageSize);
  }
  changeProductFilter(event: any) {
    this.productFilter = event.target.value;
    this.subscriptionFilter = "Paid";
    this.setPage(1, this.pageSize);
  }
}
