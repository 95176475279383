import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

import { ApiServices } from "../shared/api.services";
import { PagerService } from "../services/pager.service";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-meetings",
  templateUrl: "./meetings.component.html",
  styleUrls: ["./meetings.component.css"],
})
export class MeetingsComponent implements OnInit {
  title = this.translate.data["Meetings"];
  base_url = "";
  isAlert = false;
  alertClass = "";
  alertMsg = "";
  modelError = "";

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  meeting_id: string;

  meetingForm: FormGroup;

  meetings: any;
  meeting_data: {} = {};
  meeting_list: any;
  tot_meetings: number;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  dropDownSpeakers = [];
  selectedSpeakers = [];
  dropDownSpeakerSettings = {};

  filters: any = {};

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.getSpeakers();
    this.dropDownSpeakerSettings = {
      singleSelection: false,
      idField: "_id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
    this.setPage(1, this.pageSize);
  }

  fetchMeetings(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getMeetings(filters).subscribe((meetings) => {
        this.isLoaderShow = true;
        resolve(meetings);
      });
    });
  }

  getMeetingById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.meeting_id = _id;
      let params = {};
      params["_id"] = this.meeting_id;
      this.filters["_id"] = this.meeting_id;
      this.fetchMeetings(params).then((meeting_info) => {
        this.meeting_data = meeting_info["data"][0];
        this.initFrom();
        this.isModelShow = true;
        return;
      });
    } else {
      this.isEditable = false;
      this.meeting_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.iconSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  selectedImage: File = null;
  imageSrc: any;
  onImageSelected(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }

  getSpeakers() {
    this.apiServices.getSpeakers().subscribe((res) => {
      if (res["status"] == "success") {
        this.dropDownSpeakers = res["data"];
      }
    });
  }

  private initFrom() {
    this.modelError = "";
    let name = "";
    let description = "";
    let message = "";
    let speakers = this.fb.array([]);
    let start_date = "";
    let end_date = "";

    if (this.isEditable) {
      let meeting = this.meeting_data;
      name = meeting["name"];
      description = meeting["description"];
      message = meeting["message"];
      this.selectedSpeakers = meeting["speakers"];
      start_date = meeting["start"];
      end_date =
        meeting["end"] == "1970-01-01T00:00:00.000Z" ? "" : meeting["end"];
    }
    this.meetingForm = new FormGroup({
      name: new FormControl(name, Validators.required),
      description: new FormControl(description, Validators.required),
      message: new FormControl(message, Validators.required),
      speakers: new FormControl(speakers),
      start_date: new FormControl(start_date, Validators.required),
      end_date: new FormControl(end_date),
    });
  }

  addMeeting() {
    if (this.meetingForm.valid == true) {
      // Set speakers
      let speakers = this.selectedSpeakers.map((a) => a._id);
      this.meetingForm.value.speakers = speakers;

      // Set time zones
      this.meetingForm.value.start_date = new Date(
        this.meetingForm.value.start_date
      ).toISOString();
      this.meetingForm.value.end_date = new Date(
        this.meetingForm.value.end_date
      ).toISOString();

      this.modelError = "";
      if (this.meetingForm.value.start_date > this.meetingForm.value.end_date) {
        this.apiServices.sendTextMessage(
          "End date should be less than start date",
          "danger"
        );
        return;
      }
      if (this.isEditable) {
        this.apiServices
          .editMeeting(this.meeting_id, this.meetingForm.value)
          .subscribe((res) => {
            if (res["status"] === "success") {
              this.setPage(this.pager.currentPage, this.pageSize);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              this.apiServices.sendTextMessage(res["msg"], "danger");
              this.colseModel();
              this.scrollTop();
            }
          });
      } else {
        this.apiServices.addMeeting(this.meetingForm.value).subscribe((res) => {
          if (res["status"] === "success") {
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.colseModel();
            this.scrollTop();
          } else {
            this.apiServices.sendTextMessage(res["msg"], "danger");
            this.colseModel();
            this.scrollTop();
          }
        });
      }
    } else {
      this.modelError =
        this.translate.data["Please select all mandatory(*) values!"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    this.fetchMeetings(this.filters)
      .then((meetingData) => {
        this.meeting_list = meetingData;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.tot_meetings = this.meeting_list["total"];
        // users list
        this.meetings = this.meeting_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(
          this.tot_meetings,
          page,
          pageCount
        );
        // get current page of items
        this.pagedItems = this.meetings;
        this.pagedItemsLength = this.pagedItems.length;
      });
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }
}
