import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { ApiServices } from "../../shared/api.services";
import { PagerService } from "src/app/services/pager.service";
import { isObject } from "util";
import { TranslateService } from "src/app/shared/translate/translate.service";
declare var google;

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.css"],
})
export class JobsComponent implements OnInit {
  title = "Jobs";
  base_url;
  modelError = "";

  location_id: string;
  locations;
  currencies;
  language_id: string;
  languages;
  job_categories;

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  job_id: string;

  jobForm: FormGroup;

  jobs;
  jobs_data: {} = {};

  tot_jobs: number;
  jobs_list: any;

  //Pagination Values
  pager: any = {};
  pagedItems: any;
  pagedItemsLength: number;
  limit: number = 10;
  role: string;
  search_key: string = "";
  pageSize: number = 10;
  skip: number = 0;
  filters: any = {};

  // New Filters
  location_code: string = "";
  language_code: string = "";
  category_code: string = "";
  job_status: boolean = true;
  job_type: string = "";
  //Company
  prefectures = [];
  prefecture = "";
  city: string = "";
  town: string = "";
  //Job
  job_prefectures = [];
  job_prefecture = "";
  job_city: string = "";
  job_town: string = "";

  // Prod values
  job_pincode1 = "";
  pincode = "";

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private translate: TranslateService
  ) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.getLanguages();
    this.getJobCategories();
    this.getLocations();
    this.getCurrencies();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  getCurrencies() {
    this.apiServices.getCurrencies().subscribe((currencies) => {
      this.currencies = currencies["data"];
    });
  }

  getLocations() {
    let params = {};
    this.apiServices.getLocations({}).subscribe((locations) => {
      this.locations = locations["data"];
    });
  }

  getJobs(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getJobs(filters).subscribe((job) => {
        this.isLoaderShow = true;
        resolve(job);
      });
    });
  }

  getJobById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.job_id = _id;
      this.filters["_id"] = this.job_id;
      this.apiServices.getJobDetails(_id).subscribe((job) => {
        this.jobs_data = job["data"];
        this.getLocations();
        this.initFrom();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.jobs_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  onSearch(form: NgForm) {
    this.search_key = form.value.search_user;
    this.setPage(1, this.pageSize);
  }

  private initFrom() {
    this.apiServices.clearMessage();
    //Company
    this.prefectures = [];
    this.prefecture = "";
    this.city = "";
    this.town = "";
    //Job
    this.job_prefectures = [];
    this.job_prefecture = "";
    this.job_city = "";
    this.job_town = "";

    this.modelError = "";
    let title = "";
    let description = "";
    let job_type = "Fulltime";
    let location = "";
    let language = "";
    let category_id = "";
    let gender = "Both";
    let designation = "";
    let position = "";
    let salary = "";
    let salary_per = "";
    let currency = "";
    let start_timing: any;
    let end_timing: any;
    let start_date = new Date();
    let end_date = new Date();
    let overtime = "true";
    let qualification = "";
    let experience = "";
    let age = "";
    let website_url = "";
    let company_name = "";
    let activeStatus = "true";
    let recruitment_numbers = "";
    let phone_number = "";
    let work_schedule = "";
    let what_you_should_bring = "";
    let salary_form = "";
    let transport_expenses = "";
    let payment_conditions = "";
    let nearest_station = "";
    // let pincode = '';
    let flat_no = "";
    let company_address = "";
    let preferred_language = "";
    let preferred_lang_expertise = "";
    let display_start_date = "";
    let display_end_date = "";
    let corporate_phone_number = "";
    let contact_person_number = "";
    let contact_person_name = "";
    let tags = "";
    let application_target = "";
    // let job_pincode1 = '';
    let job_flat_no = "";
    let job_address = "";

    if (this.isEditable) {
      let job = this.jobs_data;
      title = job["title"];
      description = job["description"];
      job_type = job["job_type"];
      location = job["location"]["_id"];
      language = job["language"]["_id"];
      category_id = job["category_id"]["_id"];
      gender = job["gender"];
      designation = job["designation"];
      position = job["position"];
      salary = job["salary"];
      salary_per = job["salary_per"];
      currency = job["currency"]["_id"];
      start_timing = this.setTime(job["timing"]["start"]);
      end_timing = this.setTime(job["timing"]["end"]);
      start_date = job["start_date"];
      end_date = job["end_date"];
      overtime = `${job["overtime"]}`;
      qualification = job["qualification"];
      experience = job["experience"];
      age = job["age"];
      website_url = job["website_url"];
      company_name = job["company_name"];
      activeStatus = `${job["status"]}`;
      recruitment_numbers = job["recruitment_numbers"];
      phone_number = job["phone_number"];
      work_schedule = job["work_schedule"];
      what_you_should_bring = job["what_you_should_bring"];
      salary_form = job["salary_form"];
      transport_expenses = job["transport_expenses"];
      payment_conditions = job["payment_conditions"];
      nearest_station = job["nearest_station"];
      this.pincode = `${job["pincode"]}`;
      this.getPrefecture(this.pincode);
      this.prefecture = job["prefecture"];
      this.city = job["city"];
      this.town = job["town"];
      flat_no = job["flat_no"];
      company_address = job["company_address"];
      preferred_language = job["preferred_language"];
      preferred_lang_expertise = job["preferred_lang_expertise"];
      display_start_date = job["display_start_date"];
      display_end_date = job["display_end_date"];
      corporate_phone_number = job["corporate_phone_number"];
      contact_person_number = job["contact_person_number"];
      contact_person_name = job["contact_person_name"];
      tags = job["tags"];
      application_target = job["application_target"];

      this.job_pincode1 = `${job["job_pincode"]}`;
      this.getJobPrefecture(this.job_pincode1);
      this.job_prefecture = job["job_prefecture"];
      this.job_city = job["job_city"];
      this.job_town = job["job_town"];
      job_flat_no = job["job_flat_no"];
      job_address = job["job_address"];
    }

    this.jobForm = new FormGroup({
      title: new FormControl(title, Validators.required),
      description: new FormControl(description, Validators.required),
      job_type: new FormControl(job_type),
      location: new FormControl(location, Validators.required),
      language: new FormControl(language, Validators.required),
      category_id: new FormControl(category_id, Validators.required),
      gender: new FormControl(gender),
      designation: new FormControl(designation),
      position: new FormControl(position),
      salary: new FormControl(salary, Validators.required),
      salary_per: new FormControl(salary_per, Validators.required),
      salary_form: new FormControl(salary_form),
      currency: new FormControl(currency, Validators.required),
      start_timing: new FormControl(start_timing, Validators.required),
      end_timing: new FormControl(end_timing, Validators.required),
      start_date: new FormControl(start_date),
      end_date: new FormControl(end_date),
      overtime: new FormControl(overtime),
      qualification: new FormControl(qualification),
      experience: new FormControl(experience),
      age: new FormControl(age),
      website_url: new FormControl(website_url),
      company_name: new FormControl(company_name, Validators.required),
      status: new FormControl(activeStatus, Validators.required),
      recruitment_numbers: new FormControl(recruitment_numbers),
      phone_number: new FormControl(phone_number),
      work_schedule: new FormControl(work_schedule),
      what_you_should_bring: new FormControl(what_you_should_bring),
      transport_expenses: new FormControl(
        transport_expenses,
        Validators.required
      ),
      payment_conditions: new FormControl(payment_conditions),
      nearest_station: new FormControl(nearest_station),
      pincode: new FormControl(this.pincode),
      prefecture: new FormControl(this.prefecture),
      city: new FormControl(this.city),
      town: new FormControl(this.town),
      flat_no: new FormControl(flat_no),
      company_address: new FormControl(company_address),
      preferred_language: new FormControl(preferred_language),
      preferred_lang_expertise: new FormControl(preferred_lang_expertise),
      display_start_date: new FormControl(
        display_start_date,
        Validators.required
      ),
      display_end_date: new FormControl(display_end_date, Validators.required),
      corporate_phone_number: new FormControl(corporate_phone_number),
      contact_person_number: new FormControl(
        contact_person_number,
        Validators.required
      ),
      contact_person_name: new FormControl(
        contact_person_name,
        Validators.required
      ),
      tags: new FormControl(tags),
      application_target: new FormControl(application_target),
      job_pincode: new FormControl(this.job_pincode1),
      job_prefecture: new FormControl(this.job_prefecture),
      job_city: new FormControl(this.job_city),
      job_town: new FormControl(this.job_town),
      job_flat_no: new FormControl(job_flat_no),
      job_address: new FormControl(job_address),
    });
  }

  // FIlter results acc and Location
  onChangeLocation(event) {
    const location_code = event.target.value;
    delete this.filters.location_code;
    this.location_code = "";
    if (location_code != "") this.location_code = location_code;
    this.setPage(1, this.pageSize);
  }

  getLanguages() {
    let params = {};
    this.apiServices.getLanguages(params).subscribe((languages) => {
      this.languages = languages["data"];
    });
  }

  getJobCategories() {
    let params = {};
    this.apiServices.getJobCategories(params).subscribe((job_categories) => {
      this.job_categories = job_categories["data"];
    });
  }

  onSubmit() {
    if (this.jobForm.valid == true) {
      this.modelError = "";
      this.jobForm.value["timing"] = {};
      this.jobForm.value["timing"]["start"] = this.getTime(
        this.jobForm.value.start_timing
      );
      this.jobForm.value["timing"]["end"] = this.getTime(
        this.jobForm.value.end_timing
      );
      if (this.isEditable) {
        this.apiServices
          .editJob(this.job_id, this.jobForm.value)
          .subscribe((res) => {
            if (res["status"] === "success") {
              this.setPage(this.pager.currentPage, this.pageSize);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              // this.modelError = res['msg'];
              this.apiServices.sendTextMessage(res["msg"], "danger");
              this.colseModel();
              this.scrollTop();
            }
          });
      } else {
        this.apiServices.addJob(this.jobForm.value).subscribe((res) => {
          if (res["status"] === "success") {
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.colseModel();
            this.scrollTop();
          } else {
            this.modelError = res["msg"];
            this.apiServices.sendTextMessage(res["msg"], "danger");
            this.colseModel();
            this.scrollTop();
          }
        });
      }
    } else {
      this.modelError =
        this.translate.data["Please select all mandatory(*) values!"];
      this.scrollTop();
    }
  }

  colseModel() {
    this.jobForm.reset();
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }

  getTime(date: Date) {
    let time: any;
    date = new Date(date);
    let hours = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
    let minutes =
      date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
    return (time = hours + ":" + minutes);
  }

  setTime(time: any) {
    let date = new Date();
    let timeArray = time.split(":");
    if (timeArray.length == 2) {
      date.setHours(timeArray[0]);
      date.setMinutes(timeArray[1]);
    }
    return date;
  }

  getJobDetailsObject(job: any) {
    let jobDtails = {};
    jobDtails["description"] = job.description;
    jobDtails["age"] = job.age;
    jobDtails["recruitment_numbers"] = job.recruitment_numbers;
    jobDtails["phone_number"] = job.phone_number;
    jobDtails["work_schedule"] = job.work_schedule;
    jobDtails["what_you_should_bring"] = job.what_you_should_bring;
    jobDtails["salary_form"] = job.salary_form;
    jobDtails["transport_expenses"] = job.transport_expenses;
    jobDtails["payment_conditions"] = job.payment_conditions;
    jobDtails["nearest_station"] = job.nearest_station;
    jobDtails["prefecture"] = job.prefecture;
    jobDtails["pincode"] = job.pincode;
    jobDtails["city"] = job.city;
    jobDtails["town"] = job.town;
    jobDtails["flat_no"] = job.flat_no;
    jobDtails["company_address"] = job.company_address;

    return jobDtails;
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // Checking Object or Not
    if (!isObject(this.filters)) {
      this.filters = {};
    }

    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }
    // set search key
    this.filters["title"] = this.search_key;

    // Set prefacture
    if (this.location_code != "" && this.location_code != null) {
      this.filters["location_code"] = this.location_code;
    }
    // Set language id
    if (this.language_code != "" && this.language_code != null) {
      this.filters["language_code"] = this.language_code;
    }

    // Set category id
    if (this.category_code != "" && this.category_code != null) {
      this.filters["category_code"] = this.category_code;
    }

    // Set Job type id
    if (this.job_type != "" && this.job_type != null) {
      this.filters["job_type"] = this.job_type;
    }

    // Set Job status
    this.filters["job_status"] = this.job_status;
    this.getJobs(this.filters)
      .then((jobs) => {
        this.jobs_data = jobs;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.tot_jobs = this.jobs_data["total"];
        // users list
        this.jobs_data = this.jobs_data["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.tot_jobs, page, pageCount);
        // get current page of items
        this.pagedItems = this.jobs_data;
        this.pagedItemsLength = this.pagedItems.length;
      });
    delete this.filters.limit;
    delete this.filters.skip;
  }

  // Loading jobs based on Language
  onChangeLanguage(event: any) {
    const language_id = event.target.value;
    delete this.filters.language_code;
    this.language_code = "";
    if (language_id != "") this.language_code = language_id;
    this.setPage(1, this.pageSize);
  }

  // Loading jobs based on Category
  onChangeCategory(event: any) {
    const category_id = event.target.value;
    delete this.filters.category_code;
    this.category_code = "";
    if (category_id != "") this.category_code = category_id;
    this.setPage(1, this.pageSize);
  }

  // Loading jobs based on Job Type
  onChangeJobType(event: any) {
    const job_type = event.target.value;
    delete this.filters.job_type;
    this.job_type = "";
    if (job_type != "") this.job_type = job_type;
    this.setPage(1, this.pageSize);
  }

  // Loading jobs based on Active/Inactive
  onChangeJobStatus(event: any) {
    let job_type = event.target.value;
    job_type = job_type === "true" ? true : false;
    delete this.filters.job_status;
    this.job_status = job_type;
    this.setPage(1, this.pageSize);
  }

  resetFilters() {
    this.emptyFilters();
    this.ngOnInit();
  }

  // Emptying all status variables when reset filters
  emptyFilters() {
    this.search_key = "";
    this.location_code = "";
    this.language_code = "";
    this.category_code = "";
    this.job_type = "";
    this.job_status = true;
    delete this.filters;
  }

  onPincodeChange(pincode: any) {
    this.city = "";
    this.town = "";
    this.prefecture = "";
    this.prefectures = [];
    this.jobForm.patchValue({
      city: "",
      town: "",
      prefecture: "",
    });
    this.getPrefecture(pincode);
  }

  getPrefecture(pincode: any) {
    if (pincode.length == 7) {
      this.apiServices.getPrefecture(pincode).subscribe((data) => {
        this.prefectures = data["data"];
      });
    }
  }

  populateCityTown(prefecture: string) {
    this.prefectures.forEach((pre) => {
      if (pre.ja.prefecture == prefecture) {
        this.city = pre.ja.address1;
        this.town = pre.ja.address2;
      }
    });
  }

  onJobPincodeChange(pincode: any) {
    this.job_city = "";
    this.job_town = "";
    this.job_prefecture = "";
    this.job_prefectures = [];
    this.jobForm.patchValue({
      job_city: "",
      job_town: "",
      job_prefecture: "",
    });
    this.getJobPrefecture(pincode);
  }

  getJobPrefecture(pincode: any) {
    if (pincode.length == 7) {
      this.apiServices.getPrefecture(pincode).subscribe((data) => {
        this.job_prefectures = data["data"];
      });
    }
  }

  populateJobCityTown(prefecture: string) {
    this.job_prefectures.forEach((pre) => {
      if (pre.ja.prefecture == prefecture) {
        this.job_city = pre.ja.address1;
        this.job_town = pre.ja.address2;
      }
    });
  }

  onSetMap(event: any) {
    console.log(event);
  }
}
