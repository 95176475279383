import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ApiServices } from "../shared/api.services";
import { PagerService } from "../services/pager.service";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  title = "Send Notifications";
  notificationsForm: FormGroup;
  users = [];
  sentNotifications = [];
  isReady = false;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  //Pagination variables
  pager: any = {};
  pagedItemsLength: number;
  limit: number = 10;
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;
  params = {};
  totalCount = 0;
  //End Pagination variables

  constructor(
    private apiServices: ApiServices,
    private pagerService: PagerService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getUsers({});
    this.initFrom();
    this.getSentNotifications(1, this.pageSize);
  }

  initFrom() {
    this.notificationsForm = new FormGroup({
      title: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required),
      user_ids: new FormControl("", Validators.required),
    });
  }

  getUsers(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getUsers(filters).subscribe((users) => {
        this.users = users["data"];
      });
    });
  }

  getSentNotifications(page: number, pageCount: number) {
    this.isReady = false;
    if (page > 1) {
      this.params["limit"] = pageCount;
      this.params["skip"] = this.pager.currentPage * this.pager.pageSize;
    } else {
      this.params["limit"] = pageCount;
      this.params["skip"] = this.skip;
    }
    this.apiServices.getSentNotifications(this.params).subscribe((data) => {
      if (data["status"] === "success") {
        this.sentNotifications = data["data"];
        this.totalCount = data["count"];
        this.pager = this.pagerService.getPager(
          this.totalCount,
          page,
          pageCount
        );
        this.pagedItemsLength = this.sentNotifications.length;
        delete this.params["limit"];
        delete this.params["skip"];
      }
      this.isReady = true;
    });
  }

  onSubmit() {
    this.isReady = false;
    if (this.notificationsForm.valid == true) {
      this.apiServices
        .sendNotification(this.notificationsForm.value)
        .subscribe((res) => {
          if (res["status"] === "success") {
            this.notificationsForm.reset();
            this.apiServices.sendTextMessage(res["msg"], "success");
            this.scrollTop();
            this.getSentNotifications(1, this.pageSize);
          } else {
            this.apiServices.sendTextMessage(res["msg"], "danger");
            this.scrollTop();
          }
        });
    } else {
      let errorMessage =
        this.translate.data["Please select all mandatory(*) values!"];
      this.apiServices.sendTextMessage(errorMessage, "danger");
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
    this.isReady = true;
    this.scrollTop();
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }
}
