import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ApiServices } from "../shared/api.services";
import { NgForm } from "@angular/forms";

import { PagerService } from "../services/pager.service";
import { ngxCsv } from "ngx-csv/ngx-csv";
import * as moment from "moment-timezone";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-subscription-history",
  templateUrl: "./subscription-history.component.html",
  styleUrls: ["./subscription-history.component.css"],
})
export class SubscriptionHistoryComponent implements OnInit {
  title = this.translate.data["Subscription History"];
  subscr_history: any;
  subscr_data = {};
  subscr_id: string;
  subscr_list: any;
  subscr_count: number;
  successMsg: string;
  errorMsg: string;
  modelError = "";

  isAlert = false;
  alertClass = "";
  alertMsg = "";

  isEditable = false;
  isLoaderShow = false;
  isReadonly = false;

  products_list: Array<{}>;

  //Filters
  email_search: string;
  dateTimeRange: Date[];
  first_name: string;
  delivery_type: string = "";
  product_plan: string = "";

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private translate: TranslateService
  ) {}
  base_url: string;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  pagedItemsLength: number;
  // Set number of records per page
  limit: number = 10;
  // Pagination elements
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  ngOnInit() {
    this.base_url = this.apiServices.base_url;
    this.fetchProducts();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  fetchSubscrHistory(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.fetchSubscrHistory(filters).subscribe((sub_history) => {
        this.isLoaderShow = true;
        resolve(sub_history);
      });
    });
  }

  fetchProducts() {
    this.apiServices.fetchProducts({}).subscribe((products) => {
      this.products_list = products["data"];
    });
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }
    // set filters
    this.filters["email"] = this.email_search;
    this.filters["dateTimeRange"] = this.dateTimeRange;
    this.filters["name"] = this.first_name;
    // if (this.delivery_type != "")
    this.filters["delivery_type"] = this.delivery_type;
    // if (this.product_plan != "")
    this.filters["product_plan"] = this.product_plan;

    this.fetchSubscrHistory(this.filters)
      .then((sub_data) => {
        this.subscr_list = sub_data;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.subscr_count = this.subscr_list["total"];
        // users list
        this.subscr_history = this.subscr_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(
          this.subscr_count,
          page,
          pageCount
        );

        // get current page of items
        // this.pagedItems = this.users.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.pagedItems = this.subscr_history;
        this.pagedItemsLength = this.pagedItems.length;
        // deleting id and search key from filters
        delete this.filters.limit;
        delete this.filters.skip;
      });
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
  }

  onEmailSearch(form: NgForm) {
    this.email_search = form.value.email_search;
    this.setPage(1, this.pageSize);
  }

  onNameSearch(form: NgForm) {
    this.first_name = form.value.first_name;
    this.setPage(1, this.pageSize);
  }

  changeDeliveryType(d_type: any) {
    this.delivery_type = d_type.target.value;
    this.setPage(1, this.pageSize);
  }

  changeProductPlan(event: any) {
    this.product_plan = event.target.value;
    this.setPage(1, this.pageSize);
  }

  onDateFilter(form: NgForm) {
    this.dateTimeRange = form.value.dateTimeRange;
    this.setPage(1, this.pageSize);
  }

  resetForm() {
    this.email_search = undefined;
    this.dateTimeRange = undefined;
    this.first_name = undefined;
    this.delivery_type = "";
    this.product_plan = "";
    this.pageSize = 10;
    this.setPage(1, this.pageSize);
  }

  downloadSubscriptionHistory() {
    let data = [];
    this.apiServices
      .exportSubscrHistory(this.filters)
      .subscribe((subHistory) => {
        data = subHistory["data"];
        var options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalseparator: ".",
          showLabels: true,
          showTitle: false,
          title: "Subscription History",
          useBom: true,
          noDownload: false,
          headers: [
            "Name",
            "Kana Name",
            "Email",
            "Phone No",
            "Subscription Date",
            "Payment ID",
            "Address",
            "Postal Code",
          ],
        };
        new ngxCsv(data, "Subscriptions-" + new Date().toISOString(), options);
      });
  }
}
