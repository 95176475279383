import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ApiServices } from "../shared/api.services";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { NgForm } from "@angular/forms";

import { PagerService } from "../services/pager.service";
import { ngxCsv } from "ngx-csv/ngx-csv";
import { TranslateService } from "../shared/translate/translate.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  title = this.translate.data["Users"];
  users;
  user_data = {};
  user_id: string;
  users_list: any;
  users_count: number;
  userForm: FormGroup;
  pageSelectForm: FormGroup;
  successMsg: string;
  errorMsg: string;
  modelError = "";

  isAlert = false;
  alertClass = "";
  alertMsg = "";

  isEditable = false;
  isModelShow = false;
  isFilterShow = false;
  isLoaderShow = false;
  isReadonly = false;

  userRoleForm: FormGroup;
  name: string = "";
  email: string = "";
  job_role: string = "";
  password: string = "";
  countries: any;
  locations: any;
  location: string;
  member_role: string = "";
  languages: any;

  //Filters
  role: string = "user";
  email_search: string;
  dateTimeRange: Date[];
  first_name: string;
  last_name: string;
  email_verified: boolean;
  is_active: boolean;
  gender: string = "";
  phone_number: string;
  subscription: string = "";

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("closeManagerBtn") closeManagerBtn: ElementRef;
  @ViewChild("closeFilterBtn") closeFilterBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {}
  base_url: string;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  pagedItemsLength: number;
  // Set number of records per page
  limit: number = 10;
  // Pagination elements
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  ngOnInit() {
    this.base_url = this.apiServices.base_url;
    this.getLanguages();
    this.getLocations();
    this.initFrom();
    this.initUserRoleForm();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  changeSubscription(event: any) {
    this.subscription = event.target.value;
    this.setPage(1, this.pageSize);
  }

  getUsers(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getUsers(filters).subscribe((users) => {
        this.isLoaderShow = true;
        resolve(users);
      });
    });
  }

  getUserById(id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    if (id != "") {
      this.isEditable = true;
      this.isReadonly = true;
      this.isModelShow = false;
      this.user_id = id;
      this.filters["_id"] = id;
      this.getUsers(this.filters).then((user_info) => {
        this.user_data = user_info["data"][0];
        this.isModelShow = true;
        this.initFrom();
        this.initUserRoleForm();
        return;
      });
    } else {
      this.isEditable = false;
      this.isReadonly = false;
      this.user_data = {};
      this.initFrom();
      this.initUserRoleForm();
      this.isModelShow = true;
    }
  }

  getLanguages() {
    let params = {};
    this.apiServices.getLanguages(params).subscribe((languages) => {
      this.languages = languages["data"];
    });
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }
    // set filters
    this.filters["role"] = this.role;
    this.filters["email"] = this.email_search;
    this.filters["dateTimeRange"] = this.dateTimeRange;
    this.filters["first_name"] = this.first_name;
    this.filters["last_name"] = this.last_name;
    this.filters["email_verified"] = this.email_verified;
    this.filters["is_active"] = this.is_active;
    this.filters["gender"] = this.gender;
    this.filters["phone_number"] = this.phone_number;
    this.filters["subscription"] = this.subscription;

    this.getUsers(this.filters)
      .then((userdata) => {
        this.users_list = userdata;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.users_count = this.users_list["total"];
        // users list
        this.users = this.users_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(
          this.users_count,
          page,
          pageCount
        );

        // get current page of items
        // this.pagedItems = this.users.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.pagedItems = this.users;
        this.pagedItemsLength = this.pagedItems.length;
        // deleting id and search key from filters
        delete this.filters.limit;
        delete this.filters.skip;
      });
  }

  private initFrom() {
    this.modelError = "";
    let is_active = "true";
    if (this.isEditable) {
      let user = this.user_data;
      is_active = `${user["is_active"]}`;
    }
    this.userForm = new FormGroup({
      is_active: new FormControl(is_active, Validators.required),
    });
  }

  onSubmit() {
    this.modelError = "";
    let is_active: string;
    is_active = this.userForm.value.is_active;
    this.apiServices.editUser(this.user_id, is_active).subscribe(
      (res) => {
        if (res["status"] === "success") {
          this.apiServices.sendTextMessage(res["msg"], "success");
          this.colseModel();
          this.scrollTop();
        } else {
          // this.errorAlert(res['msg']);
          this.apiServices.sendTextMessage(res["msg"], "danger");
          this.colseModel();
          this.scrollTop();
        }
        this.setPage(this.pager.currentPage, this.pageSize);
      },
      (error) => {
        this.errorAlert(error);
      }
    );
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
    this.colseModel();
  }

  initUserRoleForm() {
    this.modelError = "";
    let form = {
      name: new FormControl(this.user_data["name"], Validators.required),
      email: new FormControl(this.user_data["email"], Validators.required),
      role: new FormControl(this.user_data["role"], Validators.required),
    };
    this.userRoleForm = new FormGroup(form);
    if (!this.isEditable) {
      this.userRoleForm.addControl(
        "password",
        new FormControl(this.password, [Validators.required])
      );
    }
    if (this.isEditable) {
      this.userRoleForm.removeControl("password");
    }
  }

  onAddMember() {
    if (this.userRoleForm.valid == true) {
      this.role = this.userRoleForm.value.role;
      if (this.isEditable) {
        this.apiServices
          .editMember(this.user_id, this.userRoleForm.value)
          .subscribe((res) => {
            if (res["status"] === "failure") {
              this.apiServices.clearMessage();
              this.apiServices.sendTextMessage(res["msg"], "danger");
            } else {
              this.apiServices.clearMessage();
              this.apiServices.sendTextMessage(res["msg"], "success");
            }
          });
        this.closeManagerBtn.nativeElement.click();
        this.setPage(1, this.pageSize);
      } else {
        this.apiServices.addMember(this.userRoleForm.value).subscribe((res) => {
          if (res["status"] === "failure") {
            this.apiServices.clearMessage();
            this.apiServices.sendTextMessage(res["msg"], "danger");
          } else {
            this.apiServices.clearMessage();
            this.apiServices.sendTextMessage(res["msg"], "success");
          }
        });
        this.closeManagerBtn.nativeElement.click();
        this.setPage(1, this.pageSize);
      }
    } else {
      this.modelError =
        this.translate.data["Please select all mandatory(*) values!"];
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      });
    }
  }

  changeRole(event: any) {
    this.role = event.target.value;
    this.setPage(1, this.pageSize);
  }

  getCountries() {
    this.apiServices.getCountries().subscribe((countries) => {
      this.countries = countries["data"];
    });
  }

  getLocations() {
    let params = {};
    this.apiServices.getLocations({}).subscribe((locations) => {
      this.locations = locations["data"];
    });
  }

  onRoleChange(event) {
    this.member_role = event.target.value;
    if (this.member_role == "jobManager") {
      this.getCountries();
      this.userRoleForm.addControl(
        "multi_locations",
        new FormControl(this.location, Validators.required)
      );
    }
  }

  onEmailSearch(form: NgForm) {
    this.email_search = form.value.email_search;
    this.setPage(1, this.pageSize);
  }

  onNameSearch(form: NgForm) {
    this.first_name = form.value.first_name;
    this.setPage(1, this.pageSize);
  }

  toggleFilters() {
    this.isFilterShow = true;
  }

  onSearchMore(form: NgForm) {
    this.email_search = form.value.email_search;
    this.dateTimeRange = form.value.dateTimeRange;
    this.first_name = form.value.first_name;
    this.last_name = form.value.last_name;
    if (form.value.email_verified == "true") {
      this.email_verified = true;
    } else if (form.value.email_verified == "false") {
      this.email_verified = false;
    }
    if (form.value.is_active == "true") {
      this.is_active = true;
    } else if (form.value.is_active == "false") {
      this.is_active = false;
    }
    this.gender = form.value.gender;
    this.phone_number = form.value.phone_number;
    this.setPage(1, this.pageSize);
    this.colseFilterModel();
  }

  colseFilterModel() {
    this.closeFilterBtn.nativeElement.click();
    this.isFilterShow = false;
  }

  resetForm() {
    this.email_search = undefined;
    this.dateTimeRange = undefined;
    this.first_name = undefined;
    this.last_name = undefined;
    this.email_verified = undefined;
    this.is_active = undefined;
    this.gender = undefined;
    this.role = "user";
    this.phone_number = undefined;
    this.subscription = "";
    this.setPage(1, this.pageSize);
  }

  downloadUsers() {
    let data = [];
    this.apiServices.getAllusers({}).subscribe((users) => {
      data = users["data"];
      var options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalseparator: ".",
        showLabels: true,
        showTitle: false,
        title: "Users",
        useBom: true,
        noDownload: false,
        headers: [
          "KYC Status",
          "Email Verified",
          "Is Active",
          "Created At",
          "Name",
          "Email",
          "Express ID",
        ],
      };
      new ngxCsv(data, "Users", options);
    });
  }

  toggleAdminAccess(user_id: string, is_permitted: boolean) {
    var con_string = is_permitted
      ? document.getElementById("unblock").innerHTML
      : document.getElementById("block").innerHTML;
    if (confirm(con_string)) {
      this.apiServices.toggleAdminAccess(user_id, is_permitted).subscribe(
        (res) => {
          if (res["status"] === "success") {
            this.apiServices.sendTextMessage(res["msg"], "success");
          } else {
            this.apiServices.sendTextMessage(res["msg"], "danger");
            // this.errorAlert(res['msg']);
          }
          this.setPage(this.pager.currentPage, this.pageSize);
        },
        (error) => {
          this.errorAlert(error);
        }
      );
    }
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }
}
